import { request } from './request'

// 获取行情数据（所有）
export function getAuctionNewsAll(data) {
  return request({
    url: '/txapi/message/getAuctionNews',
    method: 'get',
    params: data
  })
}

// 获取行情数据（前十条）
export function getAuctionNews(data) {
  return request({
    url: '/txapi/message/AuctionNewsTop10',
    method: 'get',
    params: data
  })
}

// 获取行情数据详情
export function getAuctionNewsCheck(data) {
  return request({
    url: '/txapi/message/getAuctionNewsCheck',
    method: 'get',
    params: data
  })
}

// 获取行情数据详情（上一条，下一条）
export function getAuctionNewsPageNext(data) {
  return request({
    url: '/txapi/message/getAuctionNewsPageNext',
    method: 'get',
    params: data
  })
}

// 获取行情分析过滤条件的菜单（类目）
export function getAuctionNewsMenu() {
  return request({
    url: '/txapi/message/getAuctionNewsMenu',
    method: 'get',
  })
}

// 获取行情分析过滤条件的菜单（类型）
export function getAuctionNewsMenuSort(data) {
  return request({
    url: '/txapi/message/getAuctionNewsMenuSort',
    method: 'get',
    params: data
  })
}
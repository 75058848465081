<template>
  <div class="detailAll">
    <ImgNav />
    <DetailNavBar v-if="isReady" :title="info.title" />
    <!-- <tab-bar v-if="isReady" :title="info.title" /> -->
    <div class="content w1400">
      <div class="left">
        <news-info v-if="isReady" :loading="loading" :data="info" />
        <turn-page
          v-if="isReady"
          :prev="prev"
          :next="next"
          @onClick="pageClk"
        />
        <advertisement width="800"/>
      </div>
      <div class="right">
        <q-rcode />
        <scroll-box
          v-if="isReady"
          :option="transOpt1"
          @onClick="pageClk"
          class="mrTop20"
        />
        <scroll-box
          v-if="isReady"
          :option="transOpt2"
          @onClick="pageClk"
          class="mrTop20"
        />
      </div>
    </div>
    <Spin v-if="!isReady" fix>
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <div>加载中...</div>
    </Spin>
  </div>
</template>

<script>
import merge from 'webpack-merge'

import TabBar from './compontents/delLayout/TabBar'
import NewsInfo from './compontents/delLayout/NewsInfo'
import TurnPage from './compontents/delLayout/TurnPage'

import {
  getAuctionNewsCheck,
  getAuctionNewsPageNext,
} from 'network/IndustryInfo'

export default {
  name: 'IndustryDetail',
  data() {
    return {
      isReady: false,
      loading: false,
      queryData: this.$route.query,
      info: {},
      prev: {},
      next: {},
      transOpt1: {
        title: '相关推荐',
        list: [],
        isHover: true,
        context: (str) => {
          if (str.title.length >= 10) {
            str.title = str.title.substr(0, 10) + '...'
          }
          return `[${str.marketName}] ${str.title}`
        },
      },
      transOpt2: {
        title: '热门推荐',
        list: [],
        isHover: true,
        context: (str) => {
          if (str.title.length >= 10) {
            str.title = str.title.substr(0, 10) + '...'
          }
          return `[${str.marketName}] ${str.title}`
        },
      },
    }
  },
  created() {
    this.apiRes()
  },
  methods: {
    apiRes() {
      let apis = [
        this.getData(),
        this.getData(0),
        this.getData(1),
        this.getNextData(),
      ]
      this.loading = true
      Promise.all(apis).then((res) => {
        this.loading = false
        this.isReady = true
      })
    },
    pageClk(e) {
      if (this.$route.query.id != e.id) {
        this.$router.push({
          name: 'IndustryDetail',
          query: merge(this.$route.query, {
            id: e.id,
            page: e.page,
            subjectId: e.subjectId,
          }),
        })
        this.queryData = e
        this.apiRes()
      }
    },
    /* api相关 */
    getData(num) {
      // num：0获取相关 1获取热门
      return new Promise((resolve) => {
        let data = {
          id: this.queryData.id,
          size: 1,
          num: 1,
        }
        if (num == 0) {
          data.id = null
          data.size = 20
          data.subjectId = this.queryData.subjectId
        } else if (num == 1) {
          data.id = null
          data.size = 20
        }
        getAuctionNewsCheck(data).then((res) => {
          if (res.code == 0) {
            if (num == 0) {
              this.transOpt1.list = res.data
            } else if (num == 1) {
              this.transOpt2.list = res.data
            } else {
              this.info = res.data[0]
            }
            resolve()
          }
        })
      })
    },
    getNextData() {
      return new Promise((resolve) => {
        let data = {
          page: this.queryData.page,
          size: 1,
          num: 1,
          subjectId: this.queryData.subjectId,
        }
        getAuctionNewsPageNext(data).then((res) => {
          if (res.code == 0) {
            this.prev = res.data[0]
            this.next = res.data[1]
            resolve()
          }
        })
      })
    },
  },
  components: {
    TabBar,
    NewsInfo,
    TurnPage,
  },
}
</script>

<style scoped>
.detailAll {
  position: relative;
}
.mrTop20 {
  margin-top: 20px;
}

.left,
.right {
  margin-top: 50px;
}
.content {
  display: flex;
  justify-content: space-between;
}
</style>

<template>
  <div class="all">
    <div v-if="prev.title" class="con">
      <span>上一篇：</span>
      <span @click="onClick(prev)">{{ prev.title }}</span>
    </div>
    <div v-if="next.title" class="con">
      <span>下一篇：</span>
      <span @click="onClick(next)">{{ next.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TurnPage',
  props: {
    prev: Object,
    next: Object,
  },
  data() {
    return {}
  },
  methods: {
    onClick(data){
      this.$emit('onClick', data)
    }
  }
}
</script>

<style scoped>
.all {
  padding: 20px 0;
  color: #4c4c4c;
  font-size: 14px;
  line-height: 30px;
}

.con span:last-child {
  cursor: pointer;
  color: #25449a;
  margin-left: 15px;
}

.con span:last-child:hover {
  text-decoration: underline;
}
</style>
